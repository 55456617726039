import { FC, ReactElement } from 'react';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Image, Row } from 'react-bootstrap';
import {
  ISubscription,
  ITrainingConsultation,
  ITrainingVideo
} from '../../domain/interfaces/api/ITraining';
import { ServiceTypeEnum } from '../../domain/enums/ServiceTypeEnum';
import helpers from '../../util/helpers';
import PccVideo from '../PccVideo/PccVideo';
import { getMinPriceByType } from '../../domain/utils';

const renderTrainingMedia = (
  subscriptionType: ServiceTypeEnum,
  element: ITrainingConsultation | ITrainingVideo
) => {
  return (
    <>
      {(subscriptionType === ServiceTypeEnum.VIDEO && element.trailer_url) ||
      (subscriptionType === ServiceTypeEnum.CONSULTANCY &&
        element.consultation_trailer_url) ? (
        <PccVideo
          videoPath={
            '' +
            helpers.getVideoUrl(
              subscriptionType,
              element.trailer_url,
              element.trailer_url
            )
          }
        />
      ) : (
        <Image
          loading="lazy"
          height={200}
          src={helpers.formatTrainingImage(element.image_url)}
        />
      )}
    </>
  );
};

const TrainingItem: FC<{
  element: ITrainingConsultation | ITrainingVideo;
  type: ServiceTypeEnum;
}> = (props): ReactElement => {
  let shortDescription = helpers.getTrainingShorDescription(
    props.type,
    props.element.short_description,
    props.element.short_description_video
  );
  const renderPrice = (subscriptions: ISubscription[]): string => {
    const result = getMinPriceByType(subscriptions, props.type);
    return result ? `PLN ${result.net_price}` : '';
  };

  // let duration_formaterd = helpers.formatTrainingDurantion(
  //   props.element.training_duration_min
  // );

  return (
    <Col className="training-item p-4 d-flex flex-column h-100">
      <Row>{renderTrainingMedia(props.type, props.element)}</Row>

      {/* dmc++ client told to remove for now.. */}
      {/* <span className='pill-yellow d-inline-flex align-self-start mt-4'>
        {'Category'}
      </span> */}

      <Row className="mt-4 flex-grow-1 flex-column">
        <a
          className="text-dark text-decoration-none"
          href={`/${props.type}/${props.element.slug}`}
        >
          <h3 className="training-item-title">{props.element.name}</h3>
          <p className="training-item-desc">
            {`${shortDescription.substring(0, 150)}${
              shortDescription.length > 150 ? ' (...)' : ''
            }`}
          </p>
        </a>
      </Row>

      <div
        className={`d-flex align-items-center training-details pb-3${
          props.element.instructor ? ' border-bottom' : ''
        }`}
      >
        {/* dmc++ client told to remove for now.. */}
        {/* {props.element.rating ? (
          <Col>
            <span>{props.element.rating.current_rating} </span>
            <FontAwesomeIcon icon={faStar} color='#EB8A2F' />
            <span> ({props.element.rating.nr_ratings}) </span>
          </Col>
        ) : (
          <Col className='flex-grow-1'>
            <span>5.0 </span>
            <FontAwesomeIcon icon={faStar} color='#EB8A2F' />
            <span> (123) </span>
          </Col>
        )} */}

        {/* {duration_formaterd && props.type === ServiceTypeEnum.VIDEO && (
          <Col style={{ flex: 'unset' }}>
            <FontAwesomeIcon icon={faCircle} />
            <span> {duration_formaterd} </span>
          </Col>
        )} */}

        {props.element.level && (
          <Col>
            <FontAwesomeIcon icon={faChartLine} />
            <span> {props.element.level.name}</span>
          </Col>
        )}
      </div>

      {props.element.instructor && (
        <Row className="flex-row align-items-center mt-3">
          <Col className="d-flex align-self-start align-items-center">
            <Image
              loading="lazy"
              src={props.element.instructor.photo_url || '/img/avatar.jpg'}
              width="40"
            />
            <span className="ms-2">{props.element.instructor.name}</span>
          </Col>

          <span
            className={`training-price${
              props.type === ServiceTypeEnum.VIDEO ? ' video' : ''
            }`}
          >
            {renderPrice(props.element.subscriptions)}
          </span>
        </Row>
      )}
    </Col>
  );
};

export default TrainingItem;
